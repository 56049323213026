import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
//CSS
import './css/bootstrap.css'
import './css/animate.css'

//AVULSOS
//import Potifolio from './Bifurcacao/Bifurcacao';
import Portfolio from './main/main';

ReactDOM.render(
    <div className='container-fluid p-0'>
        <Portfolio/>
    </div>, document.getElementById('root'));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
