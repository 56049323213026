import React from 'react';
import './main.css';
import AnimatedLines from '../components/animatedLines/animatedLines';
import ProfileCard from '../components/profileCard/profileCard';
import Works from '../components/works/works';

export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      WebDesignnerOpacity: 1,
      t_webDesignner_font: '',
      degrees: 0,
      backgroundPositionX1: 0,
      backgroundPositionY1: 0,
      backgroundPositionX2: 100,
      backgroundPositionY2: 0,
      backgroundPositionX3: 100,
      backgroundPositionY3: 100,
      backgroundPositionX4: 0,
      backgroundPositionY4: 100,
    }
  }

  componentWillMount() {
    this.bgGradientHandler()
  }

  getNewBGPosition(x, y) {
    if (x < 100 && y <= 0) {
      return {x:x+.1 , y:y}
    } else if (x >= 100 && y < 100) {
      return {x:x , y:y+.1}
    } else if (x > 0 && y >= 100) {
      return {x:x-.1 , y:y}
    } else{
      return {x:x , y:y-.1}
    }
  }

  bgGradientHandler() {
    if (this.state.degrees > 360) {
      this.setState({ degrees: 0 })
    } else {
      this.setState({ degrees: this.state.degrees + 1 })
    }
    
    let newPosition1  = this.getNewBGPosition(this.state.backgroundPositionX1,this.state.backgroundPositionY1)
    let newPosition2  = this.getNewBGPosition(this.state.backgroundPositionX2,this.state.backgroundPositionY2)
    let newPosition3  = this.getNewBGPosition(this.state.backgroundPositionX3,this.state.backgroundPositionY3)
    let newPosition4  = this.getNewBGPosition(this.state.backgroundPositionX4,this.state.backgroundPositionY4)

    this.setState({
      backgroundPositionX1:newPosition1.x,
      backgroundPositionY1:newPosition1.y,
      backgroundPositionX2:newPosition2.x,
      backgroundPositionY2:newPosition2.y,
      backgroundPositionX3:newPosition3.x,
      backgroundPositionY3:newPosition3.y,
      backgroundPositionX4:newPosition4.x,
      backgroundPositionY4:newPosition4.y
    })

    setTimeout(() => {
      this.bgGradientHandler()
    }, 10)
  }

  render() {
    return (
      <div className='animated fadeIn slower w-100 '>
        <AnimatedLines />
        <div className='absolute h-100 container-fluid row p-0 d-flex align-items-center'>
          <div className='col-lg-6 container'>
            <ProfileCard />
          </div>
          <div className='col-lg-6 ml-2 p-0 row justify-content-center'>
            <Works/>
          </div>
        </div>
        <div
          style={{
            zIndex:-5,
            minHeight: document.documentElement.scrollHeight -1,
            minWidth: '100vw',
            position: 'absolute',
            background: `  
                        radial-gradient(
                        circle at ${this.state.backgroundPositionX1}% ${this.state.backgroundPositionY1}%,
                        rgba(0, 255, 255, 0.4), 
                        transparent 100%
                        ),
                        radial-gradient(
                        circle at ${this.state.backgroundPositionX2}% ${this.state.backgroundPositionY2}%,
                        rgba(255, 0, 255, 0.2), 
                        transparent 100%
                        ),
                        radial-gradient(
                        at ${this.state.backgroundPositionX3}% ${this.state.backgroundPositionY3}%,
                        rgba(255, 0, 255, 0.2), 
                        transparent 200%
                        ),
                        radial-gradient(
                          at ${this.state.backgroundPositionX4}% ${this.state.backgroundPositionY4}%,
                        rgba(255, 100, 100, 0.2), 
                        transparent 200%
                        `
          }}
          className='container-fluid'>
        </div>
      </div>
    );
  }
}