import React from 'react';
import './animatedLines.css';

const limit = 600
const brightFilter = ''
const brightWidth = 80
const brightStyle = 'gradient'

let allRandomX = {
    'randomA':(Math.floor(Math.random() * 15)) + 10,
    'randomB':(Math.floor(Math.random() * 15)) + 10,
    'randomC':(Math.floor(Math.random() * 15)) + 10,
    'randomD':(Math.floor(Math.random() * 15)) + 10
}

export default class App extends React.Component {
    constructor(props){
        super(props);
        this.state = {
        }
    }
    

    percentToPxHor(intPx,canvas) {
        return Math.round(intPx * canvas.width / 100);
    }

    percentToPxVert(intPx,canvas) {
        return Math.round(intPx * canvas.height / 100);
    }

    drawLines(params){
        let {
            canvas,
            alpha,
            omega,
            randomX,
            lineWidth,
            filter,
            strokeStyle,
            reverse,
            firstDelay} = params

        let context = canvas.getContext("2d")
        context.clearRect(0, 0, canvas.width, canvas.height);
        

        let percentPosA = this.percentToPxHor(70,canvas)
        let percentPosB = this.percentToPxHor(0,canvas)
        let percentPosC = this.percentToPxHor(70,canvas)
        let percentPosD = this.percentToPxHor(0,canvas)
        let percentPosE = this.percentToPxHor(70,canvas)


        let percentPosA2 = this.percentToPxHor(60-1*1.5-alpha*0.00003*omega*allRandomX[randomX],canvas)            
        let percentPosB2 = this.percentToPxHor(60-1*1.5+alpha*0.00003*omega*allRandomX[randomX],canvas)
        let percentPosC2 = this.percentToPxHor(60-1*1.5-alpha*0.00003*omega*allRandomX[randomX],canvas)
        let percentPosD2 = this.percentToPxHor(60-1*1.5-alpha*0.00003*omega*allRandomX[randomX],canvas)
        let percentPosE2 = this.percentToPxHor(105,canvas)
        let percentPosF2 = this.percentToPxHor(60,canvas)

        context.beginPath();
        context.moveTo(-5,percentPosA)
        context.bezierCurveTo(
            0,
            percentPosA,
            percentPosB,
            percentPosC,
            percentPosD,
            percentPosE
            );
        context.bezierCurveTo(
            percentPosA2,
            percentPosB2,
            percentPosC2,
            percentPosD2,
            percentPosE2,
            percentPosF2
            );

        context.lineWidth = lineWidth;
        context.filter = filter;
        var gradient = context.createLinearGradient(0, 0, 0, canvas.height*0.9);
        if (strokeStyle === 'gradient'){
            gradient.addColorStop(0, "transparent");
            gradient.addColorStop(0.4, "rgba(125,0,255,0.3)");
            gradient.addColorStop(0.5, "rgba(255,255,255,0.05)");
            gradient.addColorStop(1, "transparent");
            context.strokeStyle = gradient;
        } else {
            context.strokeStyle = strokeStyle;
        }
        context.stroke()         
    
    
    if (reverse){
        alpha = alpha+1
        if (alpha > limit){
            reverse = false
        }
        
    } else {
        alpha = alpha-1   
        if (alpha < -1*limit){
            reverse = true
            alpha = -1*alpha
        }
    }
    
    if (alpha > 0){
        omega = limit - alpha + 1
    } else {
        omega = limit - (-1*alpha) + 1
    }

    if (alpha === 0){
        allRandomX[randomX] = (Math.floor(Math.random() * 15)) + 10
    }
    setTimeout(()=>{
        this.drawLines({
            canvas,
            alpha,
            omega,
            randomX,
            strokeStyle,
            filter,
            lineWidth,
            reverse,
            firstDelay:10})
        },firstDelay)
    }

    componentDidMount(){
        let canvasA = document.getElementById("lineA")
        let canvasA2 = document.getElementById("lineA2")
        let canvasB = document.getElementById("lineB")
        let canvasB2 = document.getElementById("lineB2")
        let canvasC = document.getElementById("lineC")
        let canvasC2 = document.getElementById("lineC2")
        let canvasD = document.getElementById("lineD")
        let canvasD2 = document.getElementById("lineD2")
        
        this.drawLines({
            canvas:canvasA,
            alpha:0,
            omega:5,
            randomX:'randomA',
            lineWidth:3,
            filter:'',
            strokeStyle:'rgba(255,255,255,0.1)',
            reverse:true,
            firstDelay:0
        })   
        
        this.drawLines({
            canvas:canvasA2,
            alpha:0,
            omega:15,
            randomX:'randomA',
            lineWidth:brightWidth,
            filter:brightFilter,
            strokeStyle:brightStyle,
            reverse:true,
            firstDelay:0
        })     
        
        this.drawLines({
            canvas:canvasB,
            alpha:0,
            omega:5,
            randomX:'randomB',
            lineWidth:3,
            filter:'',
            strokeStyle:'rgba(255,255,255,0.1)',
            reverse:true,
            firstDelay:1500
        })  

        this.drawLines({
            canvas:canvasB2,
            alpha:0,
            omega:5,
            randomX:'randomB',
            lineWidth:brightWidth,
            filter:brightFilter,
            strokeStyle:brightStyle,
            reverse:true,
            firstDelay:1500
        })     

        this.drawLines({
            canvas:canvasC,
            alpha:0,
            omega:55,
            randomX:'randomC',
            lineWidth:3,
            filter:'',
            strokeStyle:'rgba(255,255,255,0.1)',
            reverse:true,
            firstDelay:3500
        })  

        this.drawLines({
            canvas:canvasC2,
            alpha:0,
            omega:50,
            randomX:'randomC',
            lineWidth:brightWidth,
            filter:brightFilter,
            strokeStyle:brightStyle,
            reverse:true,
            firstDelay:3500
        })   
    
        this.drawLines({
            canvas:canvasD,
            alpha:0,
            omega:5,
            randomX:'randomD',
            lineWidth:3,
            filter:'',
            strokeStyle: 'rgba(255,255,255,0.1)',
            reverse:true,
            firstDelay: 5500
        })  
        
        this.drawLines({
            canvas:canvasD2,
            alpha:0,
            omega:5,
            randomX:'randomD',
            lineWidth:brightWidth,
            filter:brightFilter,
            strokeStyle:brightStyle,
            reverse:true,
            firstDelay:5500
        })   
    }

    render() {
        return (
            <div className="main">
                <div style={{minHeight:300}} className="main-center bg bg-5">
                    <canvas id='lineA' width="1000px" height="1000px" className="main-center"></canvas>
                    <canvas id='lineA2' width="1000px" height="1000px" className="main-center"></canvas>
                    <canvas id='lineB' width="1000px" height="1000px" className="main-center"></canvas>
                    <canvas id='lineB2' width="1000px" height="1000px" className="main-center"></canvas>
                    <canvas id='lineC' width="1000px" height="1000px" className="main-center"></canvas>
                    <canvas id='lineC2' width="1000px" height="1000px" className="main-center"></canvas>
                    <canvas id='lineD' width="1000px" height="1000px" className="main-center"></canvas>
                    <canvas id='lineD2' width="1000px" height="1000px" className="main-center"></canvas>
                </div>
            </div>
        );
    }
}