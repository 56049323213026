import React from 'react';
import profilePic from '../../img/profile_picture.jpg'
import {FaTwitter,FaLinkedinIn,FaGithub} from 'react-icons/fa';
const profileCircle = {
    minWidth:'150px',
    maxWidth:'150px',
    minHeight:'150px',
    maxHeight:'150px',
    backgroundImage:`url(${profilePic})`,
    backgroundSize:'cover',
    backgroundRepeat:'no-repeat',
    backgroundPosition:'40% 60%',
    border: '3px solid white',
  }

export default class ProfileCard extends React.Component{
    constructor(props){
        super(props);
        this.state = {
        }
    }

    render(){
        return (
            <div className='text-light p-5 ml-3 container text-center mouse-zoom-in'>
                <div style={profileCircle} className='rounded-circle container borde shadow'>
                </div>
                <div style={{fontSize:32}} className='display-4 text-center text-black text-shadow'>
                    Marcos O.G.S.
                </div>
                <div style={{fontSize:12}} className='display-4 text-center text-black text-shadow'>
                    Desenvolvedor de Software
                </div>
                <span><a target='_blank' rel='noopener noreferrer' href='https://twitter.com/marcosogsantos'><FaTwitter className='mr-1 text-white no-decoration mouse-zoom-in-lg'/></a></span>
                <span><a target='_blank' rel='noopener noreferrer' href='https://www.linkedin.com/in/marcosogsantos/'><FaLinkedinIn className='mr-1 text-white no-decoration mouse-zoom-in-lg'/></a></span>
                <span><a target='_blank' rel='noopener noreferrer' href='https://github.com/marcosogsantos'><FaGithub className='mr-1 text-white no-decoration mouse-zoom-in-lg'/></a></span>
            </div>
        )
    }
}