import React from 'react';
import { FaCat, FaInfoCircle } from 'react-icons/fa';
const $ = window.$;

const bgGlass = {
    backgroundColor: 'rgba(255,255,255, 0.1)',
    zIndex: 9999,
    position: 'relative',
}

const carouselInner = {
    minHeight: 260,
}

const circleCarouselIndicator = { width: 10, height: 10 }

const buildCard = (object) => {
    const { 
        title, 
        content, 
        hrefLink, 
        onBlur,
        onClick,
        popoverId, 
        popoverTitle, 
        popoverContent } = object
    return (
        <div style={bgGlass} className='hover-text-shadow rounded2-lg p-1 shadow text-white mouse-zoom-in-sm m-1 d-flex align-items-end'>
            <div>
                <a target='_blank' rel='noopener noreferrer' href={hrefLink} className='text-white no-decoration'>
                    <div className='pr-3 pt-3 pl-3'>
                        <div className="card-title" style={{ fontSize: 32, fontFamily: 'Pacifico', transform: 'translateY(-5px)' }} >
                            {title}
                        </div>
                        <div style={{ lineHeight:1.3, fontSize: 18, transform: 'translateY(-5px)' }} className='display-4'>
                            {content}
                        </div>
                    </div>
                </a>
                <div className='text-right p-0 focus'>
                    <span
                        onBlur={onBlur}
                        onClick={onClick}
                        id={popoverId}
                        tabIndex='0'
                        className='no-decoration'
                        data-placement='left'
                        data-toggle='popover'
                        data-trigger='focus'
                        data-html='true'
                        title={popoverTitle}
                        data-content={popoverContent}>
                        <FaInfoCircle className='p-0 no-decoration text-white hover-translucid' />
                    </span>
                </div>
            </div>
        </div>
    )
}

const CatCard = () => {
    return (
        <div style={{ width: 200 }} className='container carousel-caption mb-4'>
            <div style={bgGlass} className='rounded2-lg p-3 shadow text-white mouse-zoom-in-sm animated m-1'>
                <div className='mouse-zoom-in hover-text-shadow container-fluid'>
                    <a target='_blank' rel='noopener noreferrer' href='https://marcosogsantos.github.io/react-cat-css/' className='text-white text-center no-decoration p-0'>
                        <div className='pb-2' style={{ fontSize: 56, fontFamily: 'Pacifico', transform: 'translateY(-5px)' }} >
                            <FaCat />
                        </div>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default class Works extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
        this.refCarousel = React.createRef();
        this.popoverOnClick = this.popoverOnClick.bind(this)
        this.popoverOnBlur = this.popoverOnBlur.bind(this)
    }

    popoverOnClick(e) {
        e.preventDefault();
        $(this.refCarousel.current).carousel('pause')
    }

    popoverOnBlur(e) {
        e.preventDefault();
        $(this.refCarousel.current).carousel('cycle')
    }
    
    render() {
        return (
            <div ref={this.refCarousel} data-interval="false" id="carouselIndicators" style={{maxWidth: 500, transform: 'translateY(-15px)' }} className="p-2 ml-2 carousel slide">
                <ol className="carousel-indicators">
                    <li style={circleCarouselIndicator} data-target="#carouselIndicators" data-slide-to="0" className='active rounded-circle'></li>
                    <li style={circleCarouselIndicator} data-target="#carouselIndicators" data-slide-to="1" className='rounded-circle'></li>
                    <li style={circleCarouselIndicator} data-target="#carouselIndicators" data-slide-to="2" className='rounded-circle'></li>
                </ol>
                <div className="carousel-inner p-0 ml-1 container-fluid">
                    <div style={carouselInner} className="align-items-center carousel-item p-5 active">
                        {buildCard({
                            onClick:this.popoverOnClick,
                            onBlur:this.popoverOnBlur,
                            title: 'Random Bird',
                            content: 'Afim de se surpreender com um tweet aleatório do perfil de alguém?',
                            hrefLink: 'https://marcosogsantos.github.io/randombird/',
                            popoverId: 'randomBirdPopover',
                            popoverTitle: 'Informações sobre o site',
                            popoverContent: 'O Random Bird utiliza uma API personalizada para a mineração de dados públicos do Twitter, dispensando  a necessidade de qualquer API Key. Porém, os perfis com acesso restrito/privado não apresentarão nenhum resultado.'
                        })}
                    </div>
                    <div style={carouselInner} className="align-items-center carousel-item p-5 ">
                        {buildCard({
                            onClick:this.popoverOnClick,
                            onBlur:this.popoverOnBlur,
                            title: 'Bread',
                            content: 'Experimente uma nova maneira de ler os textos da internet.',
                            hrefLink: 'https://bread.marcosogsantos.com.br',
                            popoverId: 'breadPopover',
                            popoverTitle: 'Informações sobre o site',
                            popoverContent: 'Quando o usuário envia o link de alguma notícia ou artigo ao Bread, ele vasculhará a página a procura de um texto principal, que servirá como base para a nova apresentação. Faz isso apenas nas camadas públicas da fonte, não há nenhuma tentativa de quebra de segurança. Todo conteúdo encontrado está disponível tanto aos demais mecânismos de pesquisas, como o Google, quanto ao público em geral. <br/>Os únicos dados salvos no nosso banco de dados são os links para os artigos, isso significa que se a fonte do texto resolver deleta-lo ou tornar privado, o Bread não terá mais acesso e suas novas tentativas de buscas serão frustadas.'
                        })}
                    </div>
                    <div style={carouselInner} className="align-items-center carousel-item p-5">
                        <CatCard />
                    </div>
                </div>
                <a className="carousel-control-prev" href="#carouselIndicators" role="button" data-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="sr-only">Previous</span>
                </a>
                <a className="carousel-control-next" href="#carouselIndicators" role="button" data-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="sr-only">Next</span>
                </a>
            </div>

        )
    }
}